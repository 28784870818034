import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {CallMade} from '@mui/icons-material';
import {CONST_ChattinessLevel, CONST_SP_CameraLedDisabled, CONST_SP_EnableVoiceID, CONST_SP_StoreModeEnabled} from "@app/ir-q-app-common/types/unit-config";
import {RequestKey_UpdateUnitMetadata, UnitsModule} from "@modules/UnitsModule";
import {Renderer_Command} from "@renderers/push-command";
import {PushCommand} from "@app/ir-q-app-common/types/push-commands";
import {DeviceType} from "@app/ir-q-app-common/types/units";
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {Route_UnitRefurbish, Url_SupportRefurbishUnit, Url_SupportRemoteControl} from "../../../routes";
import {ApiBeyondTrustRemoteControl} from "@app-sp/app-shared/remote-control";
import {HttpMethod, OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {css} from "emotion";
import {BaseComponent, Dialog_Builder, DialogModule, RoutingModule, ToastModule, TS_Input, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {SimpleLoader} from "@components/SimpleLoader";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {StorageKey_JWT} from "@intuitionrobotics/user-account/frontend";
import FormControlLabel from '@mui/material/FormControlLabel';
import {Button, DialogTitle, Switch} from "@mui/material";
import {ComponentChattinessLevel} from "./ComponentChattinessLevel";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import moment = require("moment");

interface State {
    open: boolean;
    loading: boolean;
}

interface Props {
    pushCommands?: PushCommand[];
    unitId: string;
    product: string;
    deviceType: DeviceType;
}

const rowClass = css({
    margin: "10px 0"
})



export class Dialog_MoreCommands
    extends BaseComponent<Props, State>
    implements OnRequestListener {

    __onRequestCompleted(key: string, success: boolean, requestData: string | undefined): void {
        switch (key) {
            case RequestKey_UpdateUnitMetadata:
                return this.setState({loading: false});
        }
    }

    state: State = {
        open: false,
        loading: false
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleButtonClick = (event: any) => {
        // handle button click here
        console.log(event.currentTarget.value);
        this.handleClose();
    };
    private remoteControl = (unitId: string, deviceType: DeviceType, userName: string) => {
        XhrHttpModule
            .createRequest<ApiBeyondTrustRemoteControl>(HttpMethod.POST, "remote-control")
            .setRelativeUrl(Url_SupportRemoteControl)
            .setJsonBody({unit: {unitId, product: "elliq"}, deviceType, userName})
            .setOnSuccessMessage("Remote control request sent successfully")
            .setOnError("Remote control request failed")
            .execute();
    };

    private getUnit() {
        return {unitId: this.props.unitId, product: this.props.product};
    }

    private renderToggleButton = (key: string, text: string, onClick: () => void) => {
        const unit = this.getUnit();
        const val = !!UnitsModule.getMetadataValue(unit, key);
        return <div style={{
            paddingLeft: 8,
            marginLeft: 4,
            color: "#3f51b5",
            border: "1px solid rgba(63, 81, 181, 0.5)",
            borderRadius: 4,
            alignItems: "center",
            display: "flex",
            height: 35,

        }}>
            <FormControlLabel control={<Switch checked={val} onChange={onClick} color={"primary"}/>} label={text}/>
        </div>
    };

    private remoteControlDialog = (unitId: string, deviceType: DeviceType) => {
        this.setState({open: false});
        let userName = "";
        new Dialog_Builder(<div>
            <div>
                <TS_Input style={{width: "92%", margin: "8px 0 8px 8px", border: "solid 1px gray", outline: "none"}}
                          onChange={(_userName) => userName = _userName}
                          type={"text"} id={"remoteControlUserName"}/>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", padding: "5px"}}>
                <button onClick={() => {
                    if (!userName)
                        return ToastModule.toastError("Enter remote control user name please");

                    this.remoteControl(unitId, deviceType, userName);
                    DialogModule.close();
                }}>
                    Submit
                </button>
            </div>
        </div>)
            .setTitle(<div style={{padding: "5px"}}>Please enter remote control user name</div>)
            .setAllowIndirectClosing(true)
            .show();
    };

    private renderRefurbishUnitButton = () => {
        return <PermissionsComponent url={Url_SupportRefurbishUnit}>
            <Button
                color="primary"
                style={{marginRight: 8}}
                size="small"
                variant="outlined"
                onClick={() => {
                    RoutingModule.goToRoute(Route_UnitRefurbish, {unitId: this.props.unitId, product: "elliq"})
                }}>
                Remote-wipe Unit
            </Button>
        </PermissionsComponent>;
    };

    private renderRemoteControlButton = () => {
        const unitId: string = this.props.unitId;
        const device: DeviceType = this.props.deviceType
        return <PermissionsComponent url={Url_SupportRemoteControl}>
            <Button
                color="primary"
                style={{marginRight: 8}}
                size="small"
                variant="outlined"
                onClick={() => this.remoteControlDialog(unitId, device)}>
                Remote Control
            </Button>
        </PermissionsComponent>;
    };

    render() {
        const pushCommands = this.props.pushCommands;
        if (!pushCommands)
            return;

        const unit = this.getUnit();
        const displayButton = this.props.deviceType === 'som' || UnitsManagerModule.is3Point0Unit(unit.unitId);

        return (
            <div>
                <Button
                    style={{textTransform: "none", margin: "2px"}}
                    className={"unit_monitoring_btn"}
                    size={"small"}
                    onClick={this.handleClickOpen}
                    variant="outlined"
                >
                    More
                    <CallMade style={{width: "14px", height: "14px"}}></CallMade>
                </Button>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"More Commands"}</DialogTitle>
                    <DialogContent className={"ll_v_c"}>
                        <div className={`ll_h_c match_width ${rowClass}`}>
                            {this.renderRemoteControlButton()}
                            {displayButton && this.renderRefurbishUnitButton()}
                            {displayButton && this.resetUnit()}
                        </div>
                        <div className={`ll_h_c match_width ${rowClass}`}>
                            <div id={'toggle-buttons'}
                                 style={{display: 'flex', flexDirection: 'row', rowGap: '10px'}}>
                                {displayButton && this.renderToggleButton(CONST_SP_StoreModeEnabled, "Store Mode",
                                    () => UnitsModule.toggleUnitMetadata(unit, CONST_SP_StoreModeEnabled))}
                                {displayButton && this.renderToggleButton(CONST_SP_CameraLedDisabled, "Disable Camera Led",
                                    () => UnitsModule.toggleUnitMetadata(unit, CONST_SP_CameraLedDisabled))}
                                {displayButton && this.renderToggleButton(CONST_SP_EnableVoiceID, "Enable Voice ID Training",
                                    () => UnitsModule.toggleUnitMetadata(unit, CONST_SP_EnableVoiceID))}
                            </div>
                        </div>
                        <div className={`ll_h_c match_width ${rowClass}`} style={{flexWrap: 'wrap'}}>
                            {pushCommands.map(
                                (pushCommand, index) => {
                                    return <Renderer_Command
                                        key={index}
                                        command={pushCommand}
                                        autoWidth={true}
                                        unit={this.getUnit()}
                                        device={this.props.deviceType}
                                    />;
                                })
                            }
                        </div>
                        {this.renderChattinessLevel(unit, displayButton)}
                        {this.state.loading && <SimpleLoader overlay={true}/>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    private renderChattinessLevel(unit: { product: string; unitId: string }, displayButton: boolean | null) {
        const metadataValue = UnitsModule.getMetadata(unit, CONST_ChattinessLevel);
        const infoMeta = metadataValue?._audit?.auditBy;
        const timeStamp = metadataValue?._audit?.auditAt.timestamp;
        let infoText = "No Change";
        let timeText = "No time stamp";
        let changedBy = "OA";
        if (infoMeta !== undefined) {
            if (infoMeta.includes('@')){
                changedBy = (infoMeta.split('@')[0]);
                changedBy = "IR: " + changedBy.charAt(0).toUpperCase() + changedBy.slice(1);
            }
            if (timeStamp !== undefined) {
                timeText = moment(timeStamp).format('MM/DD/YY HH:mm')
            }
            infoText = "By " + changedBy + " on " + timeText;
        }
        return <>
            {displayButton &&
                <div className={`ll_h_c match_width ${rowClass}`} style={{flexWrap: 'wrap'}}>
                    <ComponentChattinessLevel
                        chattinessLevel={metadataValue ? metadataValue.data : 1}
                        onChange={c => {
                            this.setState({loading: true})
                            UnitsModule.updateUnitMetadata([unit.unitId], {[CONST_ChattinessLevel]: c});
                        }}
                        infoText={infoText}
                    />
                </div>
            }
        </>;
    }

    private resetUnit() {

        return <div style={{display: 'flex', flexDirection: 'row', rowGap: '10px'}}>
            <Button
                color="primary"
                style={{marginRight: 8}}
                size="small"
                variant="outlined"
                onClick={async () => {
                    this.setState({loading: true})
                    try {
                        const resp = await fetch(
                            `${PackageManagerModule.getPMFunction()}/v1/unit/reset`,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "Authorization": "Bearer " + StorageKey_JWT.get()
                                },
                                method: 'POST',
                                body: JSON.stringify({
                                    unitIds: this.props.unitId
                                })
                            }
                        )
                        if (!resp.ok) {
                            console.error(resp)
                            ToastModule.toastError("Something went wrong while resetting the unit, check logs for more details")
                        }
                    } catch (e) {
                        console.error(e)
                        ToastModule.toastError("Something went wrong while resetting the unit, check logs for more details")
                    }
                    this.setState({loading: false})
                }}>
                Reset Unit
            </Button>
        </div>


        // POST request with fetch to the reset unit endpoint


    }
}
