import * as React from "react";
import {ContactsModule} from "@modules/ContactsModule";

import {QueryParam_Product, QueryParam_UnitId, QueryParam_UserGroup, QueryParam_Version} from "@consts/common";
import {LoadableComponent} from "@components/LoadableComponent";
import {BaseComponent, RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Route_Home} from "../../routes";
import {AppModule} from "@modules/AppModule";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";

type State = {
	unitId?: string,
	product?: string
}

type Props = { hidden?: boolean, userGroup?: string, version?: string };

export class Page_Contacts_iframe
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		const urlSearchParams = new URLSearchParams(window.location.search);
		const unitId = LoadableComponent.getQueryParameter(QueryParam_UnitId) || urlSearchParams.get(QueryParam_UnitId);
		if (!unitId)
			RoutingModule.goToRoute(Route_Home);
	}

	render() {
		const urlSearchParams = new URLSearchParams(window.location.search);

		const unitId = LoadableComponent.getQueryParameter(QueryParam_UnitId) || urlSearchParams.get(QueryParam_UnitId);
		const product = LoadableComponent.getQueryParameter(QueryParam_Product) || urlSearchParams.get(QueryParam_Product) || Elliq_ProductKey;
		if (!unitId || !product)
			return null;

		const ContactsPage = ContactsModule.getContactsPage();
		if (!ContactsPage) {
			this.logError("Missing config for contacts page");
			return "Missing config for contacts page";
		}


		let _src = `${ContactsPage}?${QueryParam_UnitId}=${unitId}&${QueryParam_Product}=${product}`;
		if (this.props.userGroup)
			_src += `&${QueryParam_UserGroup}=${this.props.userGroup}`

		if (this.props.version)
			_src += `&${QueryParam_Version}=${this.props.version}`

		const src = AppModule.composeUrlWithAuth(_src);
		return <div style={{
			visibility: this.props.hidden === true ? 'hidden' : 'visible'
		}} className={"ll_v_c match_width match_height"}>
			<iframe
				className={"match_width match_height"}
				src={src}/>
		</div>;
	}
}
